<template>
  <div class="baseInfoForm-container">
    <form-panel
      class="form-container"
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitUrl="submitUrl"
      submitText="保存"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
    >
      <!-- 权限控制是否修改区域 -->
      <el-form-item
        v-if="dynamicParams && dynamicParams.committothird"
        label="是否提交集团客服"
      >
        <v-select
          v-model="form.isConvertComplaint"
          :width="width"
          :disabled="businessDisabled"
          :options="isConvertComplaintOps"
        ></v-select>
      </el-form-item>
      <el-form-item
        v-if="dynamicParams && dynamicParams.invoicesupport"
        label="支持开票"
      >
        <v-select
          v-model="form.invoiceSupport"
          :width="width"
          :disabled="businessDisabled"
          :options="invoiceSupportOps"
        ></v-select>
      </el-form-item>
      <el-form-item v-if="ticketAccount" label="临停开票账户">
        {{ ticketAccount }}
      </el-form-item>
      <el-form-item label="收款单位">
        <div class="legal-unit-item">
          <v-select2
            v-model="form.legalUnitId"
            :width="width"
            :disabled="businessDisabled"
            v-bind="legalUnitIdParams"
          ></v-select2>
          <div
            :class="[
              businessDisabled
                ? 'legal-unit-create-disabled'
                : 'legal-unit-create',
            ]"
            @click="jumpToLegalUnit"
          >
            <field-tips
              class="legal-unit-span"
              tipsText="搜索不到？立即去创建！"
            ></field-tips>
          </div>
        </div>
        <span>注：收款单位用于设定各个业务的收款账号</span>
      </el-form-item>
      <!-- 分割线 -->
      <div class="cut-line-wrapper"></div>
      <!-- 可修改区域 -->
      <el-form-item
        v-if="showLabelSelect"
        label="关联组织标签"
        :rules="[
          { required: true, message: '请选择组织标签', trigger: 'change' },
        ]"
        prop="firstOrgTagList"
      >
        <checkbox-plus
          type="default"
          :options="labelList"
          mode="checkbox-plus"
          :value.sync="form.firstOrgTagList"
        />
      </el-form-item>
      <el-form-item
        v-if="dynamicParams && dynamicParams.paymentRuleSettings === '1'"
        label="缴费规则设置"
      >
        <div class="dynamicParamsBox">
          <div class="dynamicParamsBox-item">
            <v-button text="修改设置" @click="isShowPayRule = true"></v-button>
            <div class="rlue-box" v-if="isShowPayRule">
              <div
                v-for="(item, index) in form.communityEstatesItemList"
                :key="index"
              >
                {{ item.chargeItemName }} 缴费周期：{{
                  estatesPeriodTypeMap[item.estatesPeriodType]
                }}
                缴费规则：{{ hasContinuouslyMap[item.hasContinuously] }}；
              </div>
            </div>
          </div>

          <div class="dynamicParamsBox-item">
            <v-button
              text="新湖缴费规则"
              @click="xinhuPayRuleFun"
              permission="chargerule"
            ></v-button>
            <div class="rlue-box" v-if="isShowXinhuPayRule">
              <div
                v-for="(item, index) in form.communityEstatesItemList"
                :key="index"
              >
                {{ item.chargeItemName }} 缴费周期：{{
                  estatesPeriodTypeMap[item.estatesPeriodType]
                }}
                缴费规则：{{ hasContinuouslyMap[item.hasContinuously] }}；
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="工单受理时间">
        <v-timepicker
          :isRange="true"
          :disabled="isCommunityUser"
          :startTime.sync="form.workStartTime"
          :endTime.sync="form.workEndTime"
        />
      </el-form-item>
      <el-form-item label="个人支付方式">
        <checkbox-plus
          :value.sync="perPayType"
          :options="perPayTypeOps"
          mode="checkbox-plus"
        ></checkbox-plus>
        <span
          >注：1、支付方式适用于园区缴费、停车申请、临时停车、场地预定等平台业务；2、不勾选即代表不支持个人支付</span
        >
      </el-form-item>
      <el-form-item label="企业支付方式">
        <checkbox-plus
          :value.sync="busPayType"
          :options="busPayTypeOps"
          mode="checkbox-plus"
        ></checkbox-plus>
        <span
          >注：1、支付方式适用于园区缴费、停车申请、临时停车、场地预定等平台业务；2、不勾选即代表不支持企业支付</span
        >
      </el-form-item>
      <el-form-item label="快递存放位置">
        <v-textarea v-model="form.expMemoList"></v-textarea>
      </el-form-item>

      <el-form-item label="业务授权设置">
        <div class="formItemBox">
          <div class="formItemBox-title">居民绑定房产</div>

          <el-form-item
            :rules="[{ required: true, message: '请选择', trigger: 'change' }]"
            prop="bindHouseAccredit"
          >
            <el-checkbox-group v-model="form.bindHouseAccredit">
              <el-checkbox :label="1">
                <span>用户</span>
                <span class="tips_small">(由房产的超管用户审核)</span>
              </el-checkbox>
              <el-checkbox :label="2" disabled>
                <span>物业</span>
                <span class="tips_small">(由物业审核)</span>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>

        <div class="formItemBox">
          <div class="formItemBox-title">物品出门</div>
          <el-checkbox-group v-model="form.goodsOutAccredit">
            <el-checkbox :label="2">
              <span>用户</span>
              <span class="tips_small">(由身份为“业主”的用户核实信息)</span>
            </el-checkbox>
            <el-checkbox :label="1">
              <span>物业</span>
              <span class="tips_small">(由物业核实信息)</span>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </el-form-item>
    </form-panel>
    <multi-select
      title="组织标签列表"
      :isShow.sync="isShow"
      :searchUrl="getOrgTagListUrl"
      :headers="orgTagTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="tagList"
    >
      <template #searchSlot>
        <v-input v-model="searchParams.tagName" label="标签名称"></v-input>
        <v-select2
          v-model="searchParams.parentId"
          v-bind="superiorTagParams"
          label="上级标签"
        ></v-select2>
      </template>
    </multi-select>
    <el-dialog
      title="缴费规则设置"
      :visible.sync="isShowPayRule"
      width="1000px"
      top="30px"
    >
      <div class="rule-panel">
        <div
          class="r-showPayRule-row"
          v-for="(item, index) in communityEstatesItemList"
          :key="index"
        >
          <span class="de-btn">{{ item.chargeItemName }}</span>
          <v-select
            v-model="item.estatesPeriodType"
            label="缴费周期"
            :options="estatesPeriodTypeOps"
          ></v-select>
          <v-select
            v-model="item.hasContinuously"
            label="是否连续缴费"
            :options="hasContinuouslyOps"
          ></v-select>
        </div>
      </div>
      <div class="rule-remark">
        <div>备注：</div>
        <div>
          1.缴费周期可选每月、每季度、每半年、每年，如周期选择每月，则所对应的缴费项按月收缴；
        </div>
        <div>
          2.是否连续缴费可选连续缴费、不连续缴费，选择连续缴费则表示必须缴完之前的未缴账单才能缴之后的账单，选择不连续缴费则表示不用必须缴完之前的未缴账单就能缴之后账单;
        </div>
      </div>
      <div class="footer">
        <v-button text="保存" @click="saveRule"></v-button>
      </div>
    </el-dialog>
    <el-dialog
      title="新增收款单位"
      :visible.sync="isShowLegalUnit"
      width="1000px"
      top="30px"
    >
      <legal-unit-form
        :isProjectConfig="true"
        @submitSuccess="legalUnitSubmitSuccess"
      ></legal-unit-form>
    </el-dialog>

    <!-- 新湖缴费规则设置弹窗 -->
    <el-dialog
      title="新湖缴费规则设置"
      :visible.sync="isShowXinhuPayRule"
      width="1000px"
      top="30px"
    >
      <div class="rule-table">
        <div class="rule-table-title">
          <div class="category-name">科目捆绑缴费设置</div>

          <div @click="addNewBindPayment" class="addNewBtn">+新增</div>
        </div>

        <el-table :data="xinhuTableData" border style="width: 100%">
          <el-table-column width="60" type="index" label="序号">
          </el-table-column>
          <el-table-column prop="subjectBindingGroupName" label="捆绑组合">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <div class="btnBox">
                <div @click="editTableInfoFun(scope.$index, scope.row)" class="btn">编辑</div>
                <div
                  @click="removeTableItem(scope.$index, scope.row)"
                  class="btn"
                  style="margin-left: 10px"
                >
                  删除
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="rule-panel">
        <div class="category-name">缴费周期设置</div>

        <div class="r-rowBox">
          <div
            class="r-row"
            v-for="(item, index) in xinhuPayRuleForm.chargeRuleList"
            :key="index"
          >
            <span class="de-btn">{{ item.subjectName }}</span>

            <v-select
              v-model="item.estatesPeriodType"
              label="缴费周期"
              :options="estatesPeriodTypeOps"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="rule-remark">
        <div>备注：</div>
        <div>
          缴费周期可选每月、每季度、每半年、每年，如周期选择每月，则所对应的缴费项按月收缴；
        </div>
      </div>
      <div class="footer">
        <v-button text="保存" @click="saveXinHuRule"></v-button>
      </div>
    </el-dialog>

    <el-dialog
      title="科目组合设置"
      :visible.sync="xinhuSubjectVisible"
      width="750px"
      top="30px"
    >
      <div class="contentBox">
        <div @click="addNewSubjectItem" class="btn contentBox-btn">+新增</div>

        <div class="contentBox-selectBox">
          <el-form
            :model="xinhuSubjectData"
            ref="xinhuForm"
            label-width="100px"
            class="contentBox-selectBox-form"
            :inline="true"
          >
            <el-form-item
              v-for="(domain, index) in xinhuSubjectData.subjectGroup"
              :label="'科目' + (index + 1)"
              :key="index"
              :prop="'subjectGroup.' + index + '.subject'"
              class="contentBox-selectBox-form-item"
              :rules="{
                required: true,
                message: '请选择科目',
                trigger: 'change',
              }"
            >
              <div class="flexBox">
                <v-select5
                  :communityId="xinhuPayRuleForm.communityId"
                  v-model="domain.subject"
                  v-bind="subjectParams"
                  :width="120"
                  @onChange="changeSubject"
                ></v-select5>
                <el-button
                  v-if="index > 0"
                  class="flexBox-btn"
                  type="danger"
                  size="mini"
                  @click="removeSubjectItem(domain)"
                  >删除</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div>

        <div class="footer">
          <el-button class="footer-item" @click="xinhuSubjectVisible = false"
            >取消</el-button
          >
          <el-button
            class="footer-item"
            @click="onClickSubjectSubmit('xinhuForm')"
            type="primary"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import {
  updateCommunityBusinessInfoURL,
  getlegalUnitURL,
  getOrgTagListURL,
  getSuperiorTagListURL,
  getTicketAccountURL,
  getOrgTagByTenant,
  getCommunityChargeRuleListUrl,
  getCommunityChargeSubjectListUrl,
  updateCommunityChargeRuleUrl,
} from "./../api";
import {
  estatesPeriodTypeOps,
  estatesPeriodTypeMap,
  hasContinuouslyOps,
  hasContinuouslyMap,
  orgTags,
} from "./../map";
import { CheckboxPlus, MultiSelect } from "components/bussiness";
import { vSelect5 } from "components/control";


export default {
  components: {
    CheckboxPlus,
    // ChosenListPanel,
    MultiSelect,
    "v-select5": vSelect5,
  },
  props: {
    publicParams: Object,
    responseData: Object,
  },
  data() {
    return {
      width: 180,
      estatesPeriodTypeOps,
      estatesPeriodTypeMap,
      hasContinuouslyOps,
      hasContinuouslyMap,
      submitUrl: updateCommunityBusinessInfoURL,
      showLabelSelect: false,
      labelList: [],
      orgTags,
      submitConfig: {
        submitMethod: "put",
      },
      checkboxList: [
        {
          disabled: undefined,
          label: "用户",
          value: 2,
        },
        {
          disabled: undefined,
          label: "物业",
          value: 1,
        },
      ],
      form: {
        // communityEstatesItemList: [],
        communityId: "",
        expMemoList: "",
        invoiceSupport: 1,
        isConvertComplaint: 0,
        legalUnitId: "",
        orgTagList: [],
        workEndTime: "",
        workStartTime: "",
        firstOrgTagList: [],
        goodsOutAccredit: [],
        bindHouseAccredit: [],
      },
      communityEstatesItemList: [],
      isConvertComplaintOps: [
        {
          text: "否",
          value: 0,
        },
        {
          text: "是",
          value: 1,
        },
      ],
      invoiceSupportOps: [
        {
          text: "是",
          value: 1,
        },
        {
          text: "否",
          value: 0,
        },
      ],
      perPayType: [],
      busPayType: [],
      perPayTypeOps: [
        {
          // checked: false,
          label: "线上",
          value: 1,
        },
        {
          // checked: false,
          label: "线下",
          value: 2,
        },
        {
          // checked: false,
          label: "对公转账",
          value: 3,
          disabled: true,
        },
      ],
      busPayTypeOps: [
        {
          // checked: false,
          label: "线上",
          value: 1,
        },
        {
          // checked: false,
          label: "线下",
          value: 2,
        },
        {
          // checked: false,
          label: "对公转账",
          value: 3,
        },
      ],
      legalUnitIdParams: {
        searchUrl: getlegalUnitURL,
        request: {
          text: "legalUnitName",
          value: "legalUnitId",
        },
      },
      superiorTagParams: {
        searchUrl: getSuperiorTagListURL,
        request: {
          text: "tagName",
          value: "tagId",
        },
        response: {
          text: "tagName",
          value: "id",
        },
      },
      tagList: [],
      isShow: false,
      getOrgTagListUrl: getOrgTagListURL,
      orgTagTableHeader: [
        {
          prop: "tagName",
          label: "标签名称",
        },
        {
          prop: "tagLevel",
          label: "标签层级",
        },
        {
          prop: "parentName",
          label: "上级标签",
        },
        {
          prop: "statusName",
          label: "状态",
        },
      ],
      searchParams: {
        tagName: "",
        parentId: "",
        tagLevel: 2,
        status: 1,
      },
      responseParams: {
        id: "id",
        name: "tagName",
      },
      responseKey: {
        id: "id",
        name: "text",
      },
      isShowPayRule: false,
      isShowLegalUnit: false,
      ticketAccount: "",
      isShowXinhuPayRule: false, // 新湖弹窗控制变量
      xinhuTableData: [],
      xinhuSubjectVisible: false,
      xinhuSubjectData: {
        subjectGroup: [
          {
            subject: undefined,
          },
        ],
      },
      subjectParams: {
        searchUrl: getCommunityChargeSubjectListUrl,
        method: "get",
        request: {
          text: "subjectName",
          value: "subjectCode",
        },
        response: {
          text: "subjectName",
          value: "subjectCode",
        },
      },

      xinhuPayRuleForm: {
        communityId: undefined, //小区id
        subjectBindingGroup: [], // 科目绑定组合
        chargeRuleList: [],
      },
      selectSubjectCode: undefined, // 绑定科目code判断是否重复字段
      subjectNameMap: {}, // 新湖科目MAP

      editIndex: null, // 编辑的index
    };
  },
  computed: {
    isSystemAuth() {
      let { userType } = this.$store.state.userInfo;
      return ["100", "102", "106"].indexOf(userType) > -1;
    },
    isCommunityUser() {
      let { userType } = this.$store.state.userInfo;
      return userType === "101";
    },
    dynamicParams() {
      return this.$store.state.baseConfig.dynamicParams;
    },
    // 修改项目业务信息权限
    businessDisabled() {
      let businessEdit = this.$store.getters.getPermission(
        "updateCommunityBusi"
      );
      let isEdit = this.$route.query.id !== undefined;
      return !businessEdit && isEdit;
    },
  },
  mounted() {
    this.responseData && this.responseData.data && this.setBusinessInfo();
    // if (!this.tagList.length) {
    //   this.getSuperOrgTagList()
    // }
    if (this.publicParams.id && this.responseData && this.responseData.data) {
      this.getLabelByTenant(this.responseData.data.tenantId);
    }
  },
  methods: {
    setBusinessInfo() {
      let { data } = this.responseData;
      let keyArr = Object.keys(this.form);
      let newObj = {};
      keyArr.forEach((value) => {
        if (value === "communityEstatesItemList") {
          newObj[value] = data.communityChargeItemInfoList;
        } else {
          newObj[value] = data[value];
        }
      });
      newObj.goodsOutAccredit =
        data.goodsOutAccredit !== "" ? data.goodsOutAccredit.split(",") : [1];
      newObj.goodsOutAccredit = newObj.goodsOutAccredit.map((item) => {
        return Number(item);
      });

      newObj.bindHouseAccredit =
        data.bindHouseAccredit !== ""
          ? data.bindHouseAccredit.split(",")
          : [1, 2];
      newObj.bindHouseAccredit = newObj.bindHouseAccredit.map((item) => {
        return Number(item);
      });

      this.form = newObj;
      this.communityEstatesItemList = data.communityChargeItemInfoList;
      let tagList = data.orgTagList.map((item) => {
        return {
          id: item.id,
          text: item.tagName,
        };
      });
      this.tagList = tagList;
      data.payTypeList &&
        data.payTypeList.forEach((item) => {
          if (item.payCategory === 0) {
            this.perPayType = item.subPayCategorys;
          } else if (item.payCategory === 1) {
            this.busPayType = item.subPayCategorys;
          }
        });
      this.getTicketAccount(data.communityId);
    },
    getSuperOrgTagList() {
      let _this = this;
      let superParams = { tagName: "企业" };
      let parentId;
      this.$axios
        .get(getSuperiorTagListURL, { params: superParams })
        .then((res) => {
          if (res.status === 100) {
            if (res.data.length) {
              parentId = res.data[0].id;
              _this.setOrgTagId(parentId);
            }
          }
        });
    },
    setOrgTagId(parentId) {
      let orgSearchParams = {
        parentId: parentId,
        status: 1,
        executeSearch: 1,
        curPage: 1,
        pageSize: 20,
      };
      let _this = this;
      this.$axios
        .get(this.getOrgTagListUrl, { params: orgSearchParams })
        .then((res) => {
          if (res.status === 100) {
            let list = res.data.resultList;
            if (Array.isArray(list) && list.length) {
              _this.tagList = list;
            }
          }
        });
    },
    getTicketAccount(id) {
      this.$axios
        .get(`${getTicketAccountURL}?communityId=${id}`)
        .then((res) => {
          if (res.status === 100) {
            this.ticketAccount = res.data ? res.data.taxName : undefined;
          }
        });
    },
    // 获取租户组织标签
    getLabelByTenant(tenantId) {
      this.$axios
        .get(`${getOrgTagByTenant}?tenantId=${tenantId}`)
        .then((res) => {
          if (res.status === 100) {
            let newTags = [];
            this.orgTags.forEach(function (item) {
              if (res.data.indexOf(item.value) !== -1) {
                newTags.push(item);
              }
            });
            this.labelList = newTags;
            this.showLabelSelect = res.data.length > 1;
          }
        });
    },
    jumpToLegalUnit() {
      if (this.businessDisabled) {
        // 没权限，不允许新建单位
        return;
      }
      // 权限判断
      let legalUnitAuth =
        this.$store.state.menuStore.menuPermission.shroffAccount3;
      if (legalUnitAuth && String(legalUnitAuth.add) === "1") {
        this.isShowLegalUnit = true;
      } else {
        this.$message("你没有创建收款单位的权限");
      }
    },
    // toSelectTag () {
    //   this.isShow = true
    // },
    submitBefore(data) {
      if (!this.publicParams.id) {
        this.$alert("请先添加基础信息");
        this.$emit("contact", {
          method: "setActiveLabel",
          data: "基础信息",
        });
        return false;
      }
      let payTypeArr = [];
      this.perPayType.forEach((item) => {
        payTypeArr.push({
          payCategory: 0,
          subPayCategory: item,
        });
      });
      this.busPayType.forEach((item) => {
        payTypeArr.push({
          payCategory: 1,
          subPayCategory: item,
        });
      });
      data.payTypeList = payTypeArr;
      const strList = data.goodsOutAccredit;
      data.goodsOutAccredit = strList.join(",");

      const bindHouseAccreditList = data.bindHouseAccredit;
      data.bindHouseAccredit = bindHouseAccreditList.join(",");

      return true;
    },
    // tagValid (rule, value, callback) {
    //   if (this.tagList.length > 0) {
    //     callback()
    //   } else {
    //     callback(new Error('关联组织标签'))
    //   }
    // },
    saveRule() {
      this.form.communityEstatesItemList = _.cloneDeep(
        this.communityEstatesItemList
      );
      this.isShowPayRule = false;
    },
    submitSuccess() {
      this.$emit("contact", {
        method: "setActiveLabel",
        data: "项目展示",
      });
      return false;
    },
    legalUnitSubmitSuccess(data) {
      if (data !== null) {
        this.form.legalUnitId = data;
      }
      this.isShowLegalUnit = false;
    },
    setTenantId(tenantId) {
      this.form.firstOrgTagList = [];
      this.getLabelByTenant(tenantId);
    },
    addNewBindPayment() {
      this.xinhuSubjectData = {
        subjectGroup: [
          {
            subject: undefined,
          },
        ],
      }
      this.editIndex = null;
      this.xinhuSubjectVisible = true;
    },
    onClickSubjectSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((res) => {
        if (res) {
          let subjectBindingGroupName = "";
          let subjectBindingGroupString = "";

          const selectSubjectCodeArr = _this.selectSubjectCode.split(",");
          let flag = true;

          if(_this.xinhuSubjectData.subjectGroup.length < 2) {
            _this.$message("捆绑缴费科目不能少于两个");
            return
          }

          let subjectBindingGroup = _.cloneDeep(_this.xinhuPayRuleForm.subjectBindingGroup)

          if(_this.editIndex !== null) {
            subjectBindingGroup.splice(this.editIndex, 1)
          }

          subjectBindingGroup.forEach((value) => {
            selectSubjectCodeArr.forEach((item) => {
              if (value.indexOf(item) !== -1) {
                flag = false;
              }
            });
          });

          if (flag) {
            _this.xinhuSubjectData.subjectGroup.forEach((item, index) => {
              console.log('+++item----', item, index)
              if (index > 0) {
                subjectBindingGroupName =
                  subjectBindingGroupName +
                  "," +
                  _this.subjectNameMap[item.subject];
                subjectBindingGroupString =
                  subjectBindingGroupString + "," + item.subject;
              } else {
                subjectBindingGroupName =
                  "" + _this.subjectNameMap[item.subject];
                subjectBindingGroupString = "" + item.subject;
              }
            });

            if(_this.editIndex !== null) {
              _this.xinhuTableData[_this.editIndex].subjectBindingGroupName = subjectBindingGroupName;

              _this.xinhuPayRuleForm.subjectBindingGroup[_this.editIndex] = subjectBindingGroupString;
            } else {
              _this.xinhuTableData.push({
                subjectBindingGroupName: subjectBindingGroupName,
              });
              _this.xinhuPayRuleForm.subjectBindingGroup.push(
                subjectBindingGroupString
              );
            }

            _this.xinhuSubjectData.subjectGroup = [
              {
                subject: undefined,
              },
            ];
            _this.selectSubjectCode = undefined;
            _this.xinhuSubjectVisible = false;
          } else {
            _this.$message("本次捆绑缴费科目存在重复捆绑！");
            return false;
          }
        } else {
          return false;
        }
      });
    },
    // 新增科目
    addNewSubjectItem() {
      this.xinhuSubjectData.subjectGroup.push({
        subject: undefined,
      });
    },
    // 删除科目
    removeSubjectItem(data) {
      let index = this.xinhuSubjectData.subjectGroup.indexOf(data);
      if (index !== -1) {
        this.xinhuSubjectData.subjectGroup.splice(index, 1);
      }
    },
    // 选择搜素科目
    changeSubject(data) {
      if (
        data &&
        this.selectSubjectCode &&
        this.selectSubjectCode.indexOf(data.subjectCode) !== -1
      ) {
        this.$message("请勿重复选择");
        let arr = []
        this.xinhuSubjectData.subjectGroup.forEach(item => {
          arr.push(item.subject)
        })
        let index = arr.lastIndexOf(data.subjectCode);
        this.xinhuSubjectData.subjectGroup[index].subject = undefined;
      } else if (data) {
        this.selectSubjectCode = this.selectSubjectCode
          ? this.selectSubjectCode + "," + data.subjectCode
          : data.subjectCode;
      } else {
        let selectSubjectCode = "";
        this.xinhuSubjectData.subjectGroup.forEach((item, index) => {
          if (index === 0 && item.subject) {
            selectSubjectCode = item.subject;
          } else if (item.subject) {
            selectSubjectCode = selectSubjectCode + "," + item.subject;
          }
        });
        this.selectSubjectCode = selectSubjectCode;
      }
    },
    // 删除列表数据
    removeTableItem(index) {
      this.xinhuTableData.splice(index, 1);
      this.xinhuPayRuleForm.subjectBindingGroup.splice(index, 1);
    },
    // 点击新湖缴费规则设置
    xinhuPayRuleFun() {
      const _communityId = this.form.communityId;
      let _this = this;
      this.xinhuPayRuleForm.communityId = _communityId;
      this.$axios
        .get(getCommunityChargeRuleListUrl, {
          params: { communityId: _communityId },
        })
        .then((res) => {
          if (res.status === 100) {
            if (res.data.chargeRuleInfoVList.length > 1) {
              _this.xinhuPayRuleForm.chargeRuleList =
                res.data.chargeRuleInfoVList;
              res.data.chargeRuleInfoVList.forEach((item) => {
                _this.subjectNameMap[item.subjectCode] = item.subjectName;
              });
            }
            if (res.data.bindingListVList.length > 0) {
              let xinhuTableData_arr = [];
              let xinhuPayRuleForm_arr = [];
              res.data.bindingListVList.forEach(item => {
                xinhuTableData_arr.push({
                  subjectBindingGroupName: item.subjectName
                })

                xinhuPayRuleForm_arr.push(item.subjectCode)
              })
              _this.xinhuTableData = xinhuTableData_arr;
              _this.xinhuPayRuleForm.subjectBindingGroup = xinhuPayRuleForm_arr;
            } else {
              _this.xinhuTableData = [];
              _this.xinhuPayRuleForm.subjectBindingGroup = []
            }
            this.selectSubjectCode = '';

            _this.isShowXinhuPayRule = true;
          }
        });
    },

    // 保存新湖缴费规则设置
    saveXinHuRule() {
      const headers =  {
        "Content-Type": "application/json;charset=UTF-8"
      }
      let _this = this;
      this.$axios
        .post(updateCommunityChargeRuleUrl, this.xinhuPayRuleForm, headers)
        .then(res => {
          if(res.status === 100) {
            _this.$message({
              type: 'success',
              message: '操作成功',
              center: true
            })
            _this.isShowXinhuPayRule = false;
          }
        })
    },

    // 编辑科目捆绑缴费项
    editTableInfoFun(index) {
      const arr =  this.xinhuPayRuleForm.subjectBindingGroup[index].split(',')

      const _arr = [];
      arr.forEach(item => {
        _arr.push({
          subject: item
        })
      })
      this.editIndex = index
      this.xinhuSubjectData.subjectGroup = _arr
      this.xinhuSubjectVisible = true;
    },
  },
  watch: {
    tagList(arr) {
      let ids = arr.map((value) => value.id);
      this.form.orgTagList = ids;

    },
  },
};
</script>
<style lang="scss" scoped>
.baseInfoForm-container {
  height: 100%;
}
.legal-unit-item {
  display: flex;
  flex-direction: row;
  .legal-unit-create:hover {
    cursor: pointer;
  }
  .legal-unit-create-disabled:hover {
    cursor: not-allowed;
  }
  .legal-unit-span {
    text-decoration: underline;
  }
}
.rule-remark {
  margin-top: 20px;
  div:first-child {
    font-weight: 700;
    margin-bottom: 5px;
  }
}
.rule-panel {
  margin-top: 15px;

  .r-rowBox {
    max-height: 400px;
    overflow: auto;

    .r-row {
      margin: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 90px;
      .v-control {
        display: inline-block;
      }
      > :nth-child(n) {
        margin-left: 70px;
      }
      .de-btn {
        display: inline-block;
        width: 210px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
        border: 1px solid #d5dbeb;
        text-align: center;
        border-radius: 4px;
        box-sizing: border-box;
      }
    }
  }

  .r-showPayRule-row {
    margin: 10px;
    display: flex;
    align-items: center;
    .v-control {
      display: inline-block;
    }
    > :nth-child(n) {
      margin-left: 70px;
    }
    .de-btn {
      display: inline-block;
      width: 210px;
      height: 40px;
      line-height: 40px;
      background-color: #fff;
      border: 1px solid #d5dbeb;
      text-align: center;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
}
.footer {
  text-align: center;
}
.rlue-box {
  width: 700px;
  line-height: 20px;
  div {
    display: inline-block;
    width: 50%;
  }
}
.time-panel {
  .v-control {
    display: inline-block;
  }
}

.cut-line-wrapper {
  width: 50%;
  height: 1px;
  border-bottom: 2px dashed #dcdcdc;
  margin: 20px;
}

.tips_small {
  font-size: 12px;
  color: #aaa;
}

.formItemBox {
  margin-bottom: 20px;
}

.dynamicParamsBox {
  display: flex;

  &-item:last-child {
    margin-left: 30px;
  }
}

.category-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #323233;
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  &::before {
    content: " ";
    display: block;
    width: 4px;
    height: 16px;
    background: #409eff;
    margin-right: 8px;
  }
}

.rule-table {
  display: flex;
  flex-direction: column;

  &-title {
    display: flex;
    align-items: center;

    .addNewBtn {
      color: #409eff;
      margin-bottom: 12px;
      margin-left: 40px;
      cursor: pointer;
    }
  }
}

.btnBox {
  display: flex;
  align-items: center;
}
.btn {
  color: #409eff;
  cursor: pointer;
}

.contentBox {
  display: flex;
  flex-direction: column;

  &-btn {
    margin-bottom: 20px;
  }

  &-selectBox {
    display: flex;
    flex-wrap: wrap;

    &-form {
      display: flex;
      flex-wrap: wrap;
      flex: 1;

      &-item {
        display: flex;
        width: 45%;

        .flexBox {
          display: flex;
          align-items: center;

          &-btn {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: end;
    border-top: 1px solid #dcdcdc;
    padding-top: 20px;
  }
}
</style>
