var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "project-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            staticClass: "form-container",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "el-form-item",
            { attrs: { label: "封面图片" } },
            [
              _c(
                "v-uploader",
                {
                  ref: "myUpload",
                  attrs: {
                    action: _vm.uploadUrl,
                    fileList: _vm.fileList,
                    limit: 12,
                  },
                  on: {
                    "update:fileList": function ($event) {
                      _vm.fileList = $event
                    },
                    "update:file-list": function ($event) {
                      _vm.fileList = $event
                    },
                  },
                },
                [
                  _c("div", { attrs: { slot: "tip" }, slot: "tip" }, [
                    _vm._v("注：建议宽1080px高810px，图片小于512K"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "图文描述",
                prop: "sellTeamContent",
                rules: [
                  {
                    required: true,
                    message: "请输入图文描述",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c("v-ueditor", {
                model: {
                  value: _vm.form.sellTeamContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sellTeamContent", $$v)
                  },
                  expression: "form.sellTeamContent",
                },
              }),
              _c("div", [_vm._v("图文描述内容将显示在【项目介绍】应用中")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }