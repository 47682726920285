<template>
  <div class="projectConfigForm-container">
    <tabs-panel ref="tabs" :tabs="tabs" :tabRouter="false" :before="getDetail" :activeLabel.sync="activeLabel"></tabs-panel>
  </div>
</template>
<script>
import { baseInfoForm, businessInfoForm, projectDisplayForm } from './infoTemplate'
import { getCommunityInfoURL } from './api'
import { TabsPanel } from 'components/bussiness'
export default {
  components: {
    TabsPanel
  },
  data () {
    return {
      activeLabel: '基础信息',
      tabs: [],
      public: {
        id: ''
      },
      response: {
        data: null
      }
    }
  },
  created () {
    this.tabs = [
      {
        label: '基础信息',
        component: baseInfoForm,
        props: {
          publicParams: this.public,
          responseData: this.response
        }
      }, {
        label: '业务信息',
        component: businessInfoForm,
        props: {
          publicParams: this.public,
          responseData: this.response
        }
      }, {
        label: '项目展示',
        component: projectDisplayForm,
        props: {
          publicParams: this.public,
          responseData: this.response
        }
      }
    ]
  },
  methods: {
    setActiveLabel (activeLabel) {
      this.activeLabel = activeLabel
    },
    getDetail (next) {
      if (this.$route.query.id) {
        this.public.id = this.$route.query.id
        let params = {
          params: {
            communityId: this.$route.query.id
          }
        }
        this.$axios.get(getCommunityInfoURL, params).then((res) => {
          if (res.status == 100) { //eslint-disable-line
            let { data } = res
            this.response.data = data
            next()
          }
        })
      } else {
        next()
      }
    },
    getDetail2 (id) {
      let params = {
        params: {
          communityId: id
        }
      }
      this.$axios.get(getCommunityInfoURL, params).then((res) => {
        if (res.status == 100) { //eslint-disable-line
          let { data } = res
          this.response.data = data
          this.activeLabel = '业务信息'
          this.$refs.tabs.contact({
            index: 1,
            method: 'setBusinessInfo'
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .projectConfigForm-container {
    text-align: left;
    background: #fff;
    padding: 0 20px;
  }
  .formHeight {
    height: calc(100% -  55px);
  }
</style>
