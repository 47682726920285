<template>
  <div class="project-container">
    <form-panel class="form-container" ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore">
      <el-form-item label="封面图片">
        <v-uploader
          ref="myUpload"
          :action="uploadUrl"
          :fileList.sync="fileList"
          :limit="12"
        >
          <div slot="tip">注：建议宽1080px高810px，图片小于512K</div>
        </v-uploader>
      </el-form-item>
      <el-form-item label="图文描述" prop="sellTeamContent" :rules="[{ required: true, message: '请输入图文描述', trigger: 'change' }]">
        <v-ueditor v-model="form.sellTeamContent"></v-ueditor>
        <div>图文描述内容将显示在【项目介绍】应用中</div>
      </el-form-item>
    </form-panel>
  </div>
</template>
<script>
import {
  uploadURL,
  updateCommunityIntroInfoURL
} from './../api'
import { vUploader, vUeditor } from 'components/control'
export default {
  components: {
    vUploader,
    vUeditor
  },
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      submitUrl: updateCommunityIntroInfoURL,
      uploadUrl: uploadURL,
      fileList: [],
      submitConfig: {
        submitMethod: 'put'
      },
      form: {
        id: '',
        imgUrlList: [],
        sellTeamContent: ''
      }
    }
  },
  mounted () {
    this.responseData && this.responseData.data && this.setProjectInfo()
  },
  methods: {
    setProjectInfo () {
      let { data } = this.responseData
      this.form = {
        id: data.communityId,
        imgUrlList: data.imgUrlList,
        sellTeamContent: data.sellTeamContent
      }
      let fileList = data.imgUrlList.map(value => {
        return {
          url: value
        }
      })
      this.fileList = fileList
    },
    submitBefore (data) {
      if (!this.publicParams.id) {
        this.$alert('请先添加基础信息')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '基础信息'
        })
        return false
      }
      return true
    }
  },
  watch: {
    fileList (newArr) {
      let imgUrlList = []
      newArr.forEach(value => {
        if (value.url) {
          imgUrlList.push(value.url)
        }
      })
      this.form.imgUrlList = imgUrlList
    }
  }
}
</script>

<style lang="scss" scoped>
.project-container {
  height: 100%;
}
</style>
