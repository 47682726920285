<!--
 * @Description: 项目中心 > 项目初始化 > 项目信息配置 基础信息
 * @Author: FCC
 * @Date: 2019-08-06 08:45:23
 * @LastEditors: 小广
 * @LastEditTime: 2020-05-18 09:28:32
 -->
<template>
  <div class="baseInfoForm-container">
    <form-panel class="form-container" ref="formPanel" :form="form" v-bind="submitConfig" :submitUrl="submitUrl"
      submitText="保存" :submitBefore="submitBefore" :submitSuccess="submitSuccess">
      <div class="t-text"><span class="tipIcon"></span><span>新增项目后，会建立项目默认管理员帐号，用户名为项目名称（汉字）加sa，如桂花城：桂花城sa。</span></div>
      <div class="t-text marginBot"><span class="tipIcon"></span><span>管理员请尽快修改自己的密码，保证安全。</span></div>
      <!-- 权限控制是否修改数据 -->
      <el-form-item label="项目名称" :rules="[{ required: true, message: '请输入项目名称', trigger: 'blur' }]" prop="communityName">
        <v-input v-model="form.communityName" placeholder="请输入项目名称" :width="lwidth" :disabled="baseDisabled"></v-input>
      </el-form-item>
      <el-form-item label="所属公司" :rules="[{ required: true, message: '请选择所属公司', trigger: 'change' }]" prop="tenantId">
        <v-select2 v-model="form.tenantId" :width="width" :disabled="baseDisabled" placeholder="查询公司名称"
          v-bind="regionParams" @onChange="onChange" />
      </el-form-item>
      <el-form-item label="项目交付户数">
        <v-input-number v-model="form.deliveryCount" placeholder="请输入项目交付户数" :max="99999" :width="width"
          :disabled="baseDisabled"></v-input-number>&nbsp;户
      </el-form-item>
      <el-form-item label="项目入住户数">
        <v-input-number v-model="form.totalLiveRoomCount" placeholder="请输入项目入住户数" :max="99999" :width="width"
          :disabled="baseDisabled"></v-input-number>&nbsp;户
      </el-form-item>
      <el-form-item label="项目状态" :rules="[{ required: true, message: '请选择项目状态', trigger: 'change' }]"
        prop="communityStatus">
        <v-select v-model="form.communityStatus" :options="communityStatusOps" :width="width"
          :disabled="baseDisabled"></v-select>
      </el-form-item>
      <el-form-item label="项目阶段" :rules="[{ required: true, message: '请选择项目状态', trigger: 'change' }]"
        prop="communityStage">
        <v-select v-model="form.communityStage" :options="communityStageOps" :width="width"
          :disabled="baseDisabled"></v-select>
    </el-form-item>
    <el-form-item label="项目类型" :rules="[{ required: true, message: '请选择项目类型', trigger: 'change' }]"
        prop="communityType">
        <v-select v-model="form.communityType" :options="communityTypeOps" :width="width"
          :disabled="baseDisabled"></v-select>
      </el-form-item>
      <!-- <el-form-item label="项目性质" :rules="[{ required: true, message: '请选择项目性质', trigger: 'change' }]" prop="serviceAttribute">
                                                                                                                                    <v-select v-model="form.serviceAttribute" :options="serviceAttributeOps" :width="width" :disabled="baseDisabled"></v-select>
                                                                                                                                </el-form-item> -->
      <el-form-item label="项目性质" prop="serviceAttribute">
        <v-select v-model="form.serviceAttribute" :options="serviceAttributeOps" :width="width"
          :disabled="baseDisabled"></v-select>
      </el-form-item>
      <el-form-item label="项目标签" prop="labelId">
        <chosenListPanel :list.sync="labelId" @select="choseRelationInfo"></chosenListPanel>
        <multiSelect :isShow.sync="isShowMultiSelect" :searchUrl="multiSelectUrl" :headers="multiSelectHeader"
          :backFill.sync="labelId" :searchParams="searchelseParams" :responseParams="responseParams"
          :responseKey="responseKey" :handleData="formatter" :appendToBody="true" @callback="multiSelectcallback">
          <template #searchSlot>
            <v-input v-model="searchelseParams.labelName" label="标签名称"></v-input>
            <v-select v-model="searchelseParams.labelType" label="一级分类" :options="labelTypeOps"
              @change="getCategoryId"></v-select>
            <v-select v-model="searchelseParams.categoryId" label="二级分类" :options="categoryIdOps"></v-select>
            <v-input v-model="searchelseParams.inuser" label="创建人"></v-input>
            <v-select v-model="searchelseParams.status" label="状态" :options="statusOps"></v-select>
            <v-datepicker type="rangedatetimer" :startTime.sync="searchelseParams.createStartTime"
            :endTime.sync="searchelseParams.createEndTime" label="创建时间" />
          <v-datepicker type="rangedatetimer" :startTime.sync="searchelseParams.joinStartTime"
            :endTime.sync="searchelseParams.joinEndTime" label="最近用户加入时间" />
          </template>
        </multiSelect>
      </el-form-item>
      <!-- <el-form-item label="周边范围" :rules="[{ required: true, message: '请选择项目状态', trigger: 'change' }]" prop="range">
                                                                                                                                    <v-select class="inlineBox" v-model="form.range" :options="rangeOps"></v-select>
                                                                                                                                    <label>用于框定项目周边范围</label>
                                                                                                                                  </el-form-item> -->
      <el-form-item label="管理属性" v-if="dynamicParams && dynamicParams.managementproperty">
        <v-select v-model="form.manageAttribute" :options="manageAttributeOps" :width="width"
          :disabled="baseDisabled"></v-select>
      </el-form-item>
      <el-form-item label="业务属性" v-if="dynamicParams && dynamicParams.businessproperty">
        <v-select v-model="form.serviceAttribute" :options="serviceAttributeOps" :width="width"
          :disabled="baseDisabled"></v-select>
      </el-form-item>
      <el-form-item label-width="230px" label="该项目是否用于测试或展示" :rules="[{ required: false, trigger: 'change' }]"
        prop="virtual">
        <checkbox-plus class="check-box" type="radio" :options="checkBoxVirtualOps" :value.sync="form.virtual"
          mode="radio" :disabled="baseDisabled" />
        <!-- <v-select v-model="form.virtual" :options="virtualOps" :disabled="auth"></v-select> -->
        <field-tips tipsText='用于测试或展示的项目不计入统计数据'></field-tips>
      </el-form-item>
      <el-form-item v-if="false" label="是否考核" :rules="[{ required: true, message: '请选择是否考核', trigger: 'change' }]"
        prop="needKpi">
        <v-select v-model="form.needKpi" :options="needKpiOps" :width="width" :disabled="baseDisabled"></v-select>
      </el-form-item>
      <!-- 分割线 -->
      <div class="cut-line-wrapper"></div>
      <!-- 可修改区域 -->
      <el-form-item label="项目电话"
        :rules="[{ required: true, message: '请输入项目电话', trigger: 'blur' }, { validator: validatePhone, trigger: 'blur' }]"
        prop="communityPhone">
        <v-input v-model="form.communityPhone" :maxlength="20" placeholder="请输入项目电话" :width="lwidth"></v-input>
      </el-form-item>
      <el-form-item label="项目建筑面积">
        <el-input v-model="form.communityArea" class="areaInput" type="text" :maxlength="20" placeholder="请输入项目建筑面积"
          @input="handleInput" :style="{ width: width + 'px' }">
        </el-input>&nbsp;m<sup>2</sup>
      </el-form-item>
      <el-form-item class="posStyle" label="项目经纬度" :rules="[{ required: true, validator: itude }]" prop="longitude">
        <v-input v-model="form.longitude" placeholder="经度" :width="width"></v-input>
        <v-input v-model="form.latitude" placeholder="纬度" :width="width"></v-input>
        <v-button text="地图标注" @click="getMap"></v-button>
      </el-form-item>
      <el-form-item label="项目海拔" :rules="[{ required: true, message: '请输入项目海拔' }]">
        <v-input-number v-model="form.elevation" placeholder="请输入海拔" :max="99999" :width="width"></v-input-number> m
      </el-form-item>
      <el-form-item label="项目区划" :rules="[{ required: true, validator: validateAreaCode, trigger: 'change' }]"
        prop="shequCode">
        <!-- <area-select :codes.sync="areaValue" extraClass="projectArea" :detail-addr="false" @change="areaChange" :width="lwidth"></area-select> -->
        <!-- <el-cascader v-model="areaValue" placeholder="按区域选择" :props="staProps" @change="areaChange" ref="cascader"> -->
        <area-select2 :value.sync="areaValue" placeholder="按区域选择" @change="areaChange" :width="500">
        </area-select2>
      </el-form-item>
      <el-form-item label="项目地址" :rules="[{ required: true, message: '请输入项目地址', trigger: 'blur' }]"
        prop="communityAddress">
        <v-input v-model="form.communityAddress" placeholder="请输入项目地址" :width="500"></v-input>
      </el-form-item>
      <el-form-item label="项目邮编" :rules="[{ required: true, validator: validateCode, trigger: 'blur' }]"
        prop="communityCode">
        <v-input v-model="form.communityCode" :maxlength="6" placeholder="请输入项目邮编" :width="lwidth"></v-input>
      </el-form-item>
    </form-panel>
    <v-map :selectedLng="Number(form.longitude)" :selectedLat="Number(form.latitude)" :showMapDialog.sync="showMapDialog"
      @setLngAndLat="setLngAndLat"></v-map>
  </div>
</template>
<script>
import { regionParams } from 'common/select2Params'
import * as allOwnerMgrFormUrl from './../api'
import {
  addCommunityBaseInfoURL,
  updateCommunityBaseInfoURL,
  getProjectTypesURL,
  getRoomTypesURL
} from './../api'
import {
  communityStatusOps,
  virtualOps,
  needKpiOps,
  checkBoxVirtualOps,
  manageAttributeOps,
  serviceAttributeOps,
} from './../map'
import * as commonData from './../map'
import { vMap } from 'components/control'
import { AreaSelect2, CheckboxPlus, ChosenListPanel, MultiSelect } from 'components/bussiness'
import { verifyCodeRegular, mobileRegular, telephoneRegular } from 'common/regular'
import _ from 'lodash'


export default {
  components: { vMap, AreaSelect2, CheckboxPlus, ChosenListPanel, MultiSelect },
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      width: 180,
      lwidth: 300,
      submitUrl: this.$route.query.id ? updateCommunityBaseInfoURL : addCommunityBaseInfoURL,
      areaValue: [],
      regionParams,
      hasModed: false,
      oldIsNumber: false,
      labelId: [],
      isShowMultiSelect: false,
      submitConfig: {
        submitUrl: allOwnerMgrFormUrl.createSubmitUrl,
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        submitMethod: 'POST'
      },
      labelTypeOps: commonData.labelTypeOps,
      statusOps: commonData.statusOps,
      multiSelectUrl: allOwnerMgrFormUrl.multiSelectUrl,
      categoryIdOps: [],
      searchelseParams: {
        labelName: '',
        labelType: undefined,
        categoryId: undefined,
        inuser: '',
        status: undefined,
        createStartTime: '',
        createEndTime: '',
        joinStartTime: '',
        joinEndTime: ''
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      responseParams: {
        id: 'id',
        name: 'labelName'
      },
      multiSelectHeader: [{
        prop: 'labelName',
        label: '标签名称'
      }, {
        prop: 'category',
        label: '分类'
      }, {
        prop: 'status',
        label: '状态'
      }, {
        prop: 'inuser',
        label: '创建人'
      }, {
        prop: 'intime',
        label: '创建时间'
      }, {
        prop: 'latestJoinTime',
        label: '最新用户加入时间'
      }],
      form: {
        tenantId: '',
        addrCode: '',
        cityCode: '',
        communityAddress: '',
        communityArea: '',
        communityCode: '',
        communityId: '',
        communityName: '',
        communityPhone: '',
        communityStage: undefined,
        communityStatus: 0,
        communityType: undefined,
        countryCode: '',
        dataObject: '',
        deliveryCount: undefined,
        expMemoList: '',
        invoiceSupport: '',
        isConvertComplaint: '',
        latitude: '',
        longitude: '',
        manageAttribute: 1,
        provinceCode: '',
        range: 1,
        serviceAttribute: 1,
        totalLiveRoomCount: undefined,
        needKpi: 1,
        virtual: undefined,
        streetCode: '',
        shequCode: '',
        editFirstFlag: false,
        elevation: 0, // 项目海拔
      },
      submitConfig: {
        submitMethod: this.$route.query.id ? 'put' : 'post'
      },
      rangeOps: [
        {
          text: '同城',
          value: 1
        }, {
          text: '同区/县',
          value: 2
        }
      ],
      communityStageOps: [],
      communityTypeOps: [],
      projectPosition: '',
      showMapDialog: false,
      tenantId: '',
      changeCompany: false
    }
  },
  computed: {
    communityStatusOps () {
      let [first, ...arr] = communityStatusOps //eslint-disable-line
      return arr
    },
    virtualOps () {
      let [first, ...arr] = virtualOps //eslint-disable-line
      return arr
    },
    needKpiOps () {
      let [first, ...arr] = needKpiOps //eslint-disable-line
      return arr
    },
    checkBoxVirtualOps () {
      let [first, ...arr] = checkBoxVirtualOps //eslint-disable-line
      return _.cloneDeep(arr)
    },
    manageAttributeOps () {
      let [first, ...arr] = manageAttributeOps //eslint-disable-line
      return arr
    },
    serviceAttributeOps () {
      let [first, ...arr] = serviceAttributeOps //eslint-disable-line
      return arr
    },
    dynamicParams () {
      return this.$store.state.baseConfig.dynamicParams
    },
    auth () {
      let userType = this.$store.state.userInfo.userType
      return userType === '101'
    },
    updateCommunityName () {
      return this.$store.getters.getPermission('updateCommunityName')
    },

    // 修改项目基础信息权限
    baseDisabled () {
      let baseEdit = this.$store.getters.getPermission('updateCommunityBase')
      let isEdit = this.$route.query.id !== undefined
      return !baseEdit && isEdit
    }
  },
  mounted () {
    this.getProjectTypes()
    this.getRoomTypes()
    this.responseData && this.responseData.data && this.setBaseInfo()
    // if(Number(this.form.communityArea) || Number(this.form.communityArea)==0){
    //   this.oldIsNumber = true
    // }
    document.querySelector('.areaInput input').onmousewheel = function () {
      return false
    }
  },
  methods: {
    // 选择multiSelect弹框数据之后的回调函数
    multiSelectcallback (items) {
      let _this = this
      _this.labelId = items
      // console.log(_this.labelId, "----------66666666")
    },
    choseRelationInfo () {
      this.isShowMultiSelect = true
    },
    formatter (item) {
      item.status = commonData._status[item.status]
    },
    getCategoryId (value) {
      this.$axios.get(allOwnerMgrFormUrl.getCategoryIdUrl, {
        params: {
          parentId: value
        }
      })
        .then(res => {
          let returnStatus = String(res.status)
          if (returnStatus === '100') {
            this.categoryIdOps = [{
              text: '全部',
              value: null
            }]
            let _this = this
            res.data.forEach(function (item) {
              _this.categoryIdOps.push({
                text: item.categoryName,
                value: item.id
              })
            })
          }
        })
    },
    handleInput (e) {
      this.hasModed = true
      // console.log(this.hasModed)

      // let that = this;
      // function subStr(str) {
      //   str = str.toString()
      //   str = str.replace(/^0(\d)/, "$1");
      //   str = str.replace(/^0(0\.\d)/, "$1");
      //   let length = str.length - 1;
      //   if (str.indexOf(".") > -1 && str.split(".")[1].length > 2) {
      //     that.form.communityArea = str.slice(0, length);
      //     subStr(that.form.communityArea);
      //   } else {
      //     that.form.communityArea = str;
      //   }
      // }
      // subStr(that.form.communityArea);
    },
    // blurFormatter(e){
    //   if(!this.hasModed) return
    //   this.hasModed = false
    //   this.oldIsNumber = false
    //   console.log(e)
    //   if(this.form.communityArea == ''){
    //     console.log(document.querySelector('.areaInput input').value)
    //     document.querySelector('.areaInput input').value == undefined
    //   }
    //   if(this.form.communityArea.split("").reverse().indexOf(".") == 0){
    //    this.form.communityArea = this.form.communityArea.slice(0, this.form.communityArea.length - 2)
    //   }
    //   if(this.form.communityArea.indexOf('.') == 0){
    //     this.form.communityArea = '0' +  this.form.communityArea
    //   }
    //   this.$nextTick(()=>{
    //     this.hasModed = true
    //     this.oldIsNumber = true
    //   })
    //   console.log( this.form.communityArea)
    // },
    validatePhone (rule, value, callback) {
      let regExp = mobileRegular
      let connectionRegExp = telephoneRegular
      if (!regExp.test(value) && !connectionRegExp.test(value)) {
        callback(new Error('手机/电话号码格式不正确'))
      } else {
        callback()
      }
    },
    getProjectTypes () {
      this.$axios.get(getProjectTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityStageOps = ops
          if (this.form.communityStage === undefined) {
            this.$set(this.form, 'communityStage', ops[0].value)
          }
        }
      })
    },
    getRoomTypes () {
      this.$axios.get(getRoomTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map((val) => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityTypeOps = ops
          if (this.form.communityType === undefined) {
            this.$set(this.form, 'communityType', ops[0].value)
          }
        }
      })
    },
    getMap () {
      this.showMapDialog = true
    },
    setLngAndLat (val) {
      this.form.longitude = val.lng
      this.form.latitude = val.lat
      this.form.communityAddress = val.address
      this.form.communityCode = val.postcode
      // this.areaValue = [val.areaCode]
      this.showMapDialog = false
      console.log(this.areaValue, 'aaaaaaaa啊啊')
    },
    itude (rule, value, callback) {
      if (!this.form.longitude) {
        callback(new Error('项目经度不能为空'))
      } else if (!this.form.latitude) {
        callback(new Error('项目纬度不能为空'))
      } else {
        callback()
      }
    },
    setBaseInfo () {
      this.editFirstFlag = true
      let { data } = this.responseData
      let keyArr = Object.keys(this.form)
      let newObj = {}
      keyArr.forEach(value => {
        if (value === 'virtual') {
          newObj[value] = String(data[value])
        } else {
          newObj[value] = data[value]
        }
      })

      this.form = newObj
      this.form.addrCode = data.countryAreaCode
      this.areaValue = [data.provinceAreaCode, data.cityAreaCode, data.countryAreaCode, data.streetAreaCode, data.shequAreaCode]
      this.submitUrl = updateCommunityBaseInfoURL
      this.tenantId = this.form.tenantId
      this.labelId = data.labels.map(item => ({ text: item.labelName, id: item.labelId }))
      // console.log(this.responseData, '项目', this.labelId, "\\\\\\", data.labels)
    },
    submitBefore (data) {
      data.communityLabelId = JSON.stringify(this.labelId.map(item => item.id).filter(e => e != null))
      console.log(this.hasModed, '数据', data.communityLabelId)
      if (this.hasModed) {
        this.form.communityArea = data.communityArea = data.communityArea.replace(/\s/g, '')
        const matchArr = /^(?:0|[1-9]\d*)(?:\.(\d{1,}))?$/.exec(data.communityArea)
        console.log(matchArr)
        if (matchArr) {
          if (matchArr[1] && matchArr[1].length > 2) {
            this.$message('项目建筑面积最多保留2位小数')
            return false
          }
        } else {
          if (!!this.form.communityArea) {
            this.$message('项目建筑面积必须输入数字')
            return false
          }
        }
      }
      // return !this.auth
      if (data.virtual === undefined || !data.virtual) {
        data.virtual = 0
      } else {
        data.virtual = Number(data.virtual)
      }
      return true
    },
    submitSuccess (data) {
      console.log(data, "submitSuccess")
      if (this.$route.query.id) {
        this.$message.success('修改成功')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '业务信息'
        })
        if (this.changeCompany) {
          this.$emit('contact', {
            index: 1,
            method: 'setTenantId',
            data: this.form.tenantId
          })
        }
        return false
      } else {
        this.successTip(data)
        return false
      }
    },
    onChange (item) {
      if (item != undefined) {
        if (item.id != this.tenantId) {
          this.changeCompany = true
        }
      }
    },
    async successTip (data) {
      const communityId = data.communityId
      const communityLoginName = data.communityLoginName
      const pwd = data.pwd
      let isOk = await this.$confirm('保存成功，项目账号为：' + communityLoginName + '，密码为：' + pwd + '，请及时修改登录密码。为保障项目正常运作，请您尽快完善业务信息。', {
        cancelButtonText: '暂不设置',
        confirmButtonText: '前往设置'
      })
      if (isOk) {
        if (this.publicParams.id) {
          this.$emit('contact', {
            method: 'setActiveLabel',
            data: '业务信息'
          })
        } else {
          this.publicParams.id = communityId
          this.$emit('contact', {
            method: 'getDetail2',
            data: communityId
          })
        }
      } else {
        this.$router.go(-1)
      }
    },
    areaChange (val) {
      console.log(val, "val----区划")
      if (this.editFirstFlag) {
        this.editFirstFlag = false
        return
      } else {
        if (val.area) {
          this.form.addrCode = val.area.value
          this.form.cityCode = val.city.label
          this.form.countryCode = val.area.label
          this.form.provinceCode = val.province.label
          this.form.streetCode = val.street.label
          this.form.shequCode = val.shequ.label
        } else {
          this.form.addrCode = ''
          this.form.cityCode = ''
          this.form.countryCode = ''
          this.form.provinceCode = ''
          this.form.streetCode = ''
          this.form.shequCode = ''
        }
      }
    },
    validateCode (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入邮政编码'))
      } else if (!verifyCodeRegular.test(value)) {
        callback(new Error('请输入正确的邮政编码'))
      } else {
        callback()
      }
    },
    validateAreaCode (rule, value, callback) {
      console.log(value, 'value是啥')
      if (value.length > 0) {
        callback()
      } else {
        callback(new Error('请选择项目区划'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// .areaInput ::v-deep {
//     input::-webkit-outer-spin-button,
//     input::-webkit-inner-spin-button{
//         -webkit-appearance: none !important;
//     }
//     input[type="number"]{
//         -moz-appearance:textfield;
//     }
// }
.baseInfoForm-container {
  // overflow: auto;
  height: 100%;
}

.marginBot {
  margin-bottom: 20px;
}

.inlineBox {
  display: inline;
  margin-right: 20px;
}

.posStyle {
  .v-control {
    margin-right: 15px;
  }
}

.t-text {
  padding-left: 20px;
  position: relative;
}

.tipIcon {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('./tipIcon.png') no-repeat;
  top: 0;
  left: 0;
}

.check-box {
  display: flex;
  flex-direction: row;
}

.cut-line-wrapper {
  width: 50%;
  height: 1px;
  border-bottom: 2px dashed #DCDCDC;
  margin: 20px;
}
</style>
