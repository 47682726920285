var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfoForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            staticClass: "form-container",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _vm.dynamicParams && _vm.dynamicParams.committothird
            ? _c(
                "el-form-item",
                { attrs: { label: "是否提交集团客服" } },
                [
                  _c("v-select", {
                    attrs: {
                      width: _vm.width,
                      disabled: _vm.businessDisabled,
                      options: _vm.isConvertComplaintOps,
                    },
                    model: {
                      value: _vm.form.isConvertComplaint,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isConvertComplaint", $$v)
                      },
                      expression: "form.isConvertComplaint",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.dynamicParams && _vm.dynamicParams.invoicesupport
            ? _c(
                "el-form-item",
                { attrs: { label: "支持开票" } },
                [
                  _c("v-select", {
                    attrs: {
                      width: _vm.width,
                      disabled: _vm.businessDisabled,
                      options: _vm.invoiceSupportOps,
                    },
                    model: {
                      value: _vm.form.invoiceSupport,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "invoiceSupport", $$v)
                      },
                      expression: "form.invoiceSupport",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.ticketAccount
            ? _c("el-form-item", { attrs: { label: "临停开票账户" } }, [
                _vm._v("\n      " + _vm._s(_vm.ticketAccount) + "\n    "),
              ])
            : _vm._e(),
          _c("el-form-item", { attrs: { label: "收款单位" } }, [
            _c(
              "div",
              { staticClass: "legal-unit-item" },
              [
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: {
                        width: _vm.width,
                        disabled: _vm.businessDisabled,
                      },
                      model: {
                        value: _vm.form.legalUnitId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "legalUnitId", $$v)
                        },
                        expression: "form.legalUnitId",
                      },
                    },
                    "v-select2",
                    _vm.legalUnitIdParams,
                    false
                  )
                ),
                _c(
                  "div",
                  {
                    class: [
                      _vm.businessDisabled
                        ? "legal-unit-create-disabled"
                        : "legal-unit-create",
                    ],
                    on: { click: _vm.jumpToLegalUnit },
                  },
                  [
                    _c("field-tips", {
                      staticClass: "legal-unit-span",
                      attrs: { tipsText: "搜索不到？立即去创建！" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("span", [_vm._v("注：收款单位用于设定各个业务的收款账号")]),
          ]),
          _c("div", { staticClass: "cut-line-wrapper" }),
          _vm.showLabelSelect
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "关联组织标签",
                    rules: [
                      {
                        required: true,
                        message: "请选择组织标签",
                        trigger: "change",
                      },
                    ],
                    prop: "firstOrgTagList",
                  },
                },
                [
                  _c("checkbox-plus", {
                    attrs: {
                      type: "default",
                      options: _vm.labelList,
                      mode: "checkbox-plus",
                      value: _vm.form.firstOrgTagList,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.form, "firstOrgTagList", $event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.dynamicParams && _vm.dynamicParams.paymentRuleSettings === "1"
            ? _c("el-form-item", { attrs: { label: "缴费规则设置" } }, [
                _c("div", { staticClass: "dynamicParamsBox" }, [
                  _c(
                    "div",
                    { staticClass: "dynamicParamsBox-item" },
                    [
                      _c("v-button", {
                        attrs: { text: "修改设置" },
                        on: {
                          click: function ($event) {
                            _vm.isShowPayRule = true
                          },
                        },
                      }),
                      _vm.isShowPayRule
                        ? _c(
                            "div",
                            { staticClass: "rlue-box" },
                            _vm._l(
                              _vm.form.communityEstatesItemList,
                              function (item, index) {
                                return _c("div", { key: index }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.chargeItemName) +
                                      " 缴费周期：" +
                                      _vm._s(
                                        _vm.estatesPeriodTypeMap[
                                          item.estatesPeriodType
                                        ]
                                      ) +
                                      "\n              缴费规则：" +
                                      _vm._s(
                                        _vm.hasContinuouslyMap[
                                          item.hasContinuously
                                        ]
                                      ) +
                                      "；\n            "
                                  ),
                                ])
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "dynamicParamsBox-item" },
                    [
                      _c("v-button", {
                        attrs: {
                          text: "新湖缴费规则",
                          permission: "chargerule",
                        },
                        on: { click: _vm.xinhuPayRuleFun },
                      }),
                      _vm.isShowXinhuPayRule
                        ? _c(
                            "div",
                            { staticClass: "rlue-box" },
                            _vm._l(
                              _vm.form.communityEstatesItemList,
                              function (item, index) {
                                return _c("div", { key: index }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.chargeItemName) +
                                      " 缴费周期：" +
                                      _vm._s(
                                        _vm.estatesPeriodTypeMap[
                                          item.estatesPeriodType
                                        ]
                                      ) +
                                      "\n              缴费规则：" +
                                      _vm._s(
                                        _vm.hasContinuouslyMap[
                                          item.hasContinuously
                                        ]
                                      ) +
                                      "；\n            "
                                  ),
                                ])
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "工单受理时间" } },
            [
              _c("v-timepicker", {
                attrs: {
                  isRange: true,
                  disabled: _vm.isCommunityUser,
                  startTime: _vm.form.workStartTime,
                  endTime: _vm.form.workEndTime,
                },
                on: {
                  "update:startTime": function ($event) {
                    return _vm.$set(_vm.form, "workStartTime", $event)
                  },
                  "update:start-time": function ($event) {
                    return _vm.$set(_vm.form, "workStartTime", $event)
                  },
                  "update:endTime": function ($event) {
                    return _vm.$set(_vm.form, "workEndTime", $event)
                  },
                  "update:end-time": function ($event) {
                    return _vm.$set(_vm.form, "workEndTime", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "个人支付方式" } },
            [
              _c("checkbox-plus", {
                attrs: {
                  value: _vm.perPayType,
                  options: _vm.perPayTypeOps,
                  mode: "checkbox-plus",
                },
                on: {
                  "update:value": function ($event) {
                    _vm.perPayType = $event
                  },
                },
              }),
              _c("span", [
                _vm._v(
                  "注：1、支付方式适用于园区缴费、停车申请、临时停车、场地预定等平台业务；2、不勾选即代表不支持个人支付"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "企业支付方式" } },
            [
              _c("checkbox-plus", {
                attrs: {
                  value: _vm.busPayType,
                  options: _vm.busPayTypeOps,
                  mode: "checkbox-plus",
                },
                on: {
                  "update:value": function ($event) {
                    _vm.busPayType = $event
                  },
                },
              }),
              _c("span", [
                _vm._v(
                  "注：1、支付方式适用于园区缴费、停车申请、临时停车、场地预定等平台业务；2、不勾选即代表不支持企业支付"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "快递存放位置" } },
            [
              _c("v-textarea", {
                model: {
                  value: _vm.form.expMemoList,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "expMemoList", $$v)
                  },
                  expression: "form.expMemoList",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "业务授权设置" } }, [
            _c(
              "div",
              { staticClass: "formItemBox" },
              [
                _c("div", { staticClass: "formItemBox-title" }, [
                  _vm._v("居民绑定房产"),
                ]),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      rules: [
                        {
                          required: true,
                          message: "请选择",
                          trigger: "change",
                        },
                      ],
                      prop: "bindHouseAccredit",
                    },
                  },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.form.bindHouseAccredit,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bindHouseAccredit", $$v)
                          },
                          expression: "form.bindHouseAccredit",
                        },
                      },
                      [
                        _c("el-checkbox", { attrs: { label: 1 } }, [
                          _c("span", [_vm._v("用户")]),
                          _c("span", { staticClass: "tips_small" }, [
                            _vm._v("(由房产的超管用户审核)"),
                          ]),
                        ]),
                        _c(
                          "el-checkbox",
                          { attrs: { label: 2, disabled: "" } },
                          [
                            _c("span", [_vm._v("物业")]),
                            _c("span", { staticClass: "tips_small" }, [
                              _vm._v("(由物业审核)"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "formItemBox" },
              [
                _c("div", { staticClass: "formItemBox-title" }, [
                  _vm._v("物品出门"),
                ]),
                _c(
                  "el-checkbox-group",
                  {
                    model: {
                      value: _vm.form.goodsOutAccredit,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goodsOutAccredit", $$v)
                      },
                      expression: "form.goodsOutAccredit",
                    },
                  },
                  [
                    _c("el-checkbox", { attrs: { label: 2 } }, [
                      _c("span", [_vm._v("用户")]),
                      _c("span", { staticClass: "tips_small" }, [
                        _vm._v("(由身份为“业主”的用户核实信息)"),
                      ]),
                    ]),
                    _c("el-checkbox", { attrs: { label: 1 } }, [
                      _c("span", [_vm._v("物业")]),
                      _c("span", { staticClass: "tips_small" }, [
                        _vm._v("(由物业核实信息)"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "组织标签列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getOrgTagListUrl,
          headers: _vm.orgTagTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.tagList,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            _vm.tagList = $event
          },
          "update:back-fill": function ($event) {
            _vm.tagList = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "标签名称" },
                  model: {
                    value: _vm.searchParams.tagName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "tagName", $$v)
                    },
                    expression: "searchParams.tagName",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "上级标签" },
                      model: {
                        value: _vm.searchParams.parentId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "parentId", $$v)
                        },
                        expression: "searchParams.parentId",
                      },
                    },
                    "v-select2",
                    _vm.superiorTagParams,
                    false
                  )
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "缴费规则设置",
            visible: _vm.isShowPayRule,
            width: "1000px",
            top: "30px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowPayRule = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "rule-panel" },
            _vm._l(_vm.communityEstatesItemList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "r-showPayRule-row" },
                [
                  _c("span", { staticClass: "de-btn" }, [
                    _vm._v(_vm._s(item.chargeItemName)),
                  ]),
                  _c("v-select", {
                    attrs: {
                      label: "缴费周期",
                      options: _vm.estatesPeriodTypeOps,
                    },
                    model: {
                      value: item.estatesPeriodType,
                      callback: function ($$v) {
                        _vm.$set(item, "estatesPeriodType", $$v)
                      },
                      expression: "item.estatesPeriodType",
                    },
                  }),
                  _c("v-select", {
                    attrs: {
                      label: "是否连续缴费",
                      options: _vm.hasContinuouslyOps,
                    },
                    model: {
                      value: item.hasContinuously,
                      callback: function ($$v) {
                        _vm.$set(item, "hasContinuously", $$v)
                      },
                      expression: "item.hasContinuously",
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
          _c("div", { staticClass: "rule-remark" }, [
            _c("div", [_vm._v("备注：")]),
            _c("div", [
              _vm._v(
                "\n        1.缴费周期可选每月、每季度、每半年、每年，如周期选择每月，则所对应的缴费项按月收缴；\n      "
              ),
            ]),
            _c("div", [
              _vm._v(
                "\n        2.是否连续缴费可选连续缴费、不连续缴费，选择连续缴费则表示必须缴完之前的未缴账单才能缴之后的账单，选择不连续缴费则表示不用必须缴完之前的未缴账单就能缴之后账单;\n      "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c("v-button", {
                attrs: { text: "保存" },
                on: { click: _vm.saveRule },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增收款单位",
            visible: _vm.isShowLegalUnit,
            width: "1000px",
            top: "30px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowLegalUnit = $event
            },
          },
        },
        [
          _c("legal-unit-form", {
            attrs: { isProjectConfig: true },
            on: { submitSuccess: _vm.legalUnitSubmitSuccess },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新湖缴费规则设置",
            visible: _vm.isShowXinhuPayRule,
            width: "1000px",
            top: "30px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowXinhuPayRule = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "rule-table" },
            [
              _c("div", { staticClass: "rule-table-title" }, [
                _c("div", { staticClass: "category-name" }, [
                  _vm._v("科目捆绑缴费设置"),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "addNewBtn",
                    on: { click: _vm.addNewBindPayment },
                  },
                  [_vm._v("+新增")]
                ),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.xinhuTableData, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "60", type: "index", label: "序号" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "subjectBindingGroupName",
                      label: "捆绑组合",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "btnBox" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editTableInfoFun(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "btn",
                                  staticStyle: { "margin-left": "10px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeTableItem(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                删除\n              "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "rule-panel" }, [
            _c("div", { staticClass: "category-name" }, [
              _vm._v("缴费周期设置"),
            ]),
            _c(
              "div",
              { staticClass: "r-rowBox" },
              _vm._l(
                _vm.xinhuPayRuleForm.chargeRuleList,
                function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "r-row" },
                    [
                      _c("span", { staticClass: "de-btn" }, [
                        _vm._v(_vm._s(item.subjectName)),
                      ]),
                      _c("v-select", {
                        attrs: {
                          label: "缴费周期",
                          options: _vm.estatesPeriodTypeOps,
                        },
                        model: {
                          value: item.estatesPeriodType,
                          callback: function ($$v) {
                            _vm.$set(item, "estatesPeriodType", $$v)
                          },
                          expression: "item.estatesPeriodType",
                        },
                      }),
                    ],
                    1
                  )
                }
              ),
              0
            ),
          ]),
          _c("div", { staticClass: "rule-remark" }, [
            _c("div", [_vm._v("备注：")]),
            _c("div", [
              _vm._v(
                "\n        缴费周期可选每月、每季度、每半年、每年，如周期选择每月，则所对应的缴费项按月收缴；\n      "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c("v-button", {
                attrs: { text: "保存" },
                on: { click: _vm.saveXinHuRule },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "科目组合设置",
            visible: _vm.xinhuSubjectVisible,
            width: "750px",
            top: "30px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.xinhuSubjectVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "contentBox" }, [
            _c(
              "div",
              {
                staticClass: "btn contentBox-btn",
                on: { click: _vm.addNewSubjectItem },
              },
              [_vm._v("+新增")]
            ),
            _c(
              "div",
              { staticClass: "contentBox-selectBox" },
              [
                _c(
                  "el-form",
                  {
                    ref: "xinhuForm",
                    staticClass: "contentBox-selectBox-form",
                    attrs: {
                      model: _vm.xinhuSubjectData,
                      "label-width": "100px",
                      inline: true,
                    },
                  },
                  _vm._l(
                    _vm.xinhuSubjectData.subjectGroup,
                    function (domain, index) {
                      return _c(
                        "el-form-item",
                        {
                          key: index,
                          staticClass: "contentBox-selectBox-form-item",
                          attrs: {
                            label: "科目" + (index + 1),
                            prop: "subjectGroup." + index + ".subject",
                            rules: {
                              required: true,
                              message: "请选择科目",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flexBox" },
                            [
                              _c(
                                "v-select5",
                                _vm._b(
                                  {
                                    attrs: {
                                      communityId:
                                        _vm.xinhuPayRuleForm.communityId,
                                      width: 120,
                                    },
                                    on: { onChange: _vm.changeSubject },
                                    model: {
                                      value: domain.subject,
                                      callback: function ($$v) {
                                        _vm.$set(domain, "subject", $$v)
                                      },
                                      expression: "domain.subject",
                                    },
                                  },
                                  "v-select5",
                                  _vm.subjectParams,
                                  false
                                )
                              ),
                              index > 0
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "flexBox-btn",
                                      attrs: { type: "danger", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeSubjectItem(domain)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "footer-item",
                    on: {
                      click: function ($event) {
                        _vm.xinhuSubjectVisible = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "footer-item",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.onClickSubjectSubmit("xinhuForm")
                      },
                    },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }