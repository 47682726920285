var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfoForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            staticClass: "form-container",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c("div", { staticClass: "t-text" }, [
            _c("span", { staticClass: "tipIcon" }),
            _c("span", [
              _vm._v(
                "新增项目后，会建立项目默认管理员帐号，用户名为项目名称（汉字）加sa，如桂花城：桂花城sa。"
              ),
            ]),
          ]),
          _c("div", { staticClass: "t-text marginBot" }, [
            _c("span", { staticClass: "tipIcon" }),
            _c("span", [_vm._v("管理员请尽快修改自己的密码，保证安全。")]),
          ]),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目名称",
                rules: [
                  {
                    required: true,
                    message: "请输入项目名称",
                    trigger: "blur",
                  },
                ],
                prop: "communityName",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  placeholder: "请输入项目名称",
                  width: _vm.lwidth,
                  disabled: _vm.baseDisabled,
                },
                model: {
                  value: _vm.form.communityName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityName", $$v)
                  },
                  expression: "form.communityName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "所属公司",
                rules: [
                  {
                    required: true,
                    message: "请选择所属公司",
                    trigger: "change",
                  },
                ],
                prop: "tenantId",
              },
            },
            [
              _c(
                "v-select2",
                _vm._b(
                  {
                    attrs: {
                      width: _vm.width,
                      disabled: _vm.baseDisabled,
                      placeholder: "查询公司名称",
                    },
                    on: { onChange: _vm.onChange },
                    model: {
                      value: _vm.form.tenantId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tenantId", $$v)
                      },
                      expression: "form.tenantId",
                    },
                  },
                  "v-select2",
                  _vm.regionParams,
                  false
                )
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "项目交付户数" } },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入项目交付户数",
                  max: 99999,
                  width: _vm.width,
                  disabled: _vm.baseDisabled,
                },
                model: {
                  value: _vm.form.deliveryCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deliveryCount", $$v)
                  },
                  expression: "form.deliveryCount",
                },
              }),
              _vm._v(" 户\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "项目入住户数" } },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入项目入住户数",
                  max: 99999,
                  width: _vm.width,
                  disabled: _vm.baseDisabled,
                },
                model: {
                  value: _vm.form.totalLiveRoomCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "totalLiveRoomCount", $$v)
                  },
                  expression: "form.totalLiveRoomCount",
                },
              }),
              _vm._v(" 户\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目状态",
                rules: [
                  {
                    required: true,
                    message: "请选择项目状态",
                    trigger: "change",
                  },
                ],
                prop: "communityStatus",
              },
            },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.communityStatusOps,
                  width: _vm.width,
                  disabled: _vm.baseDisabled,
                },
                model: {
                  value: _vm.form.communityStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityStatus", $$v)
                  },
                  expression: "form.communityStatus",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目阶段",
                rules: [
                  {
                    required: true,
                    message: "请选择项目状态",
                    trigger: "change",
                  },
                ],
                prop: "communityStage",
              },
            },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.communityStageOps,
                  width: _vm.width,
                  disabled: _vm.baseDisabled,
                },
                model: {
                  value: _vm.form.communityStage,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityStage", $$v)
                  },
                  expression: "form.communityStage",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目类型",
                rules: [
                  {
                    required: true,
                    message: "请选择项目类型",
                    trigger: "change",
                  },
                ],
                prop: "communityType",
              },
            },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.communityTypeOps,
                  width: _vm.width,
                  disabled: _vm.baseDisabled,
                },
                model: {
                  value: _vm.form.communityType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityType", $$v)
                  },
                  expression: "form.communityType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "项目性质", prop: "serviceAttribute" } },
            [
              _c("v-select", {
                attrs: {
                  options: _vm.serviceAttributeOps,
                  width: _vm.width,
                  disabled: _vm.baseDisabled,
                },
                model: {
                  value: _vm.form.serviceAttribute,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "serviceAttribute", $$v)
                  },
                  expression: "form.serviceAttribute",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "项目标签", prop: "labelId" } },
            [
              _c("chosenListPanel", {
                attrs: { list: _vm.labelId },
                on: {
                  "update:list": function ($event) {
                    _vm.labelId = $event
                  },
                  select: _vm.choseRelationInfo,
                },
              }),
              _c("multiSelect", {
                attrs: {
                  isShow: _vm.isShowMultiSelect,
                  searchUrl: _vm.multiSelectUrl,
                  headers: _vm.multiSelectHeader,
                  backFill: _vm.labelId,
                  searchParams: _vm.searchelseParams,
                  responseParams: _vm.responseParams,
                  responseKey: _vm.responseKey,
                  handleData: _vm.formatter,
                  appendToBody: true,
                },
                on: {
                  "update:isShow": function ($event) {
                    _vm.isShowMultiSelect = $event
                  },
                  "update:is-show": function ($event) {
                    _vm.isShowMultiSelect = $event
                  },
                  "update:backFill": function ($event) {
                    _vm.labelId = $event
                  },
                  "update:back-fill": function ($event) {
                    _vm.labelId = $event
                  },
                  callback: _vm.multiSelectcallback,
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchSlot",
                    fn: function () {
                      return [
                        _c("v-input", {
                          attrs: { label: "标签名称" },
                          model: {
                            value: _vm.searchelseParams.labelName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchelseParams, "labelName", $$v)
                            },
                            expression: "searchelseParams.labelName",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "一级分类",
                            options: _vm.labelTypeOps,
                          },
                          on: { change: _vm.getCategoryId },
                          model: {
                            value: _vm.searchelseParams.labelType,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchelseParams, "labelType", $$v)
                            },
                            expression: "searchelseParams.labelType",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "二级分类",
                            options: _vm.categoryIdOps,
                          },
                          model: {
                            value: _vm.searchelseParams.categoryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchelseParams, "categoryId", $$v)
                            },
                            expression: "searchelseParams.categoryId",
                          },
                        }),
                        _c("v-input", {
                          attrs: { label: "创建人" },
                          model: {
                            value: _vm.searchelseParams.inuser,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchelseParams, "inuser", $$v)
                            },
                            expression: "searchelseParams.inuser",
                          },
                        }),
                        _c("v-select", {
                          attrs: { label: "状态", options: _vm.statusOps },
                          model: {
                            value: _vm.searchelseParams.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchelseParams, "status", $$v)
                            },
                            expression: "searchelseParams.status",
                          },
                        }),
                        _c("v-datepicker", {
                          attrs: {
                            type: "rangedatetimer",
                            startTime: _vm.searchelseParams.createStartTime,
                            endTime: _vm.searchelseParams.createEndTime,
                            label: "创建时间",
                          },
                          on: {
                            "update:startTime": function ($event) {
                              return _vm.$set(
                                _vm.searchelseParams,
                                "createStartTime",
                                $event
                              )
                            },
                            "update:start-time": function ($event) {
                              return _vm.$set(
                                _vm.searchelseParams,
                                "createStartTime",
                                $event
                              )
                            },
                            "update:endTime": function ($event) {
                              return _vm.$set(
                                _vm.searchelseParams,
                                "createEndTime",
                                $event
                              )
                            },
                            "update:end-time": function ($event) {
                              return _vm.$set(
                                _vm.searchelseParams,
                                "createEndTime",
                                $event
                              )
                            },
                          },
                        }),
                        _c("v-datepicker", {
                          attrs: {
                            type: "rangedatetimer",
                            startTime: _vm.searchelseParams.joinStartTime,
                            endTime: _vm.searchelseParams.joinEndTime,
                            label: "最近用户加入时间",
                          },
                          on: {
                            "update:startTime": function ($event) {
                              return _vm.$set(
                                _vm.searchelseParams,
                                "joinStartTime",
                                $event
                              )
                            },
                            "update:start-time": function ($event) {
                              return _vm.$set(
                                _vm.searchelseParams,
                                "joinStartTime",
                                $event
                              )
                            },
                            "update:endTime": function ($event) {
                              return _vm.$set(
                                _vm.searchelseParams,
                                "joinEndTime",
                                $event
                              )
                            },
                            "update:end-time": function ($event) {
                              return _vm.$set(
                                _vm.searchelseParams,
                                "joinEndTime",
                                $event
                              )
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.dynamicParams && _vm.dynamicParams.managementproperty
            ? _c(
                "el-form-item",
                { attrs: { label: "管理属性" } },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.manageAttributeOps,
                      width: _vm.width,
                      disabled: _vm.baseDisabled,
                    },
                    model: {
                      value: _vm.form.manageAttribute,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "manageAttribute", $$v)
                      },
                      expression: "form.manageAttribute",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.dynamicParams && _vm.dynamicParams.businessproperty
            ? _c(
                "el-form-item",
                { attrs: { label: "业务属性" } },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.serviceAttributeOps,
                      width: _vm.width,
                      disabled: _vm.baseDisabled,
                    },
                    model: {
                      value: _vm.form.serviceAttribute,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "serviceAttribute", $$v)
                      },
                      expression: "form.serviceAttribute",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "230px",
                label: "该项目是否用于测试或展示",
                rules: [{ required: false, trigger: "change" }],
                prop: "virtual",
              },
            },
            [
              _c("checkbox-plus", {
                staticClass: "check-box",
                attrs: {
                  type: "radio",
                  options: _vm.checkBoxVirtualOps,
                  value: _vm.form.virtual,
                  mode: "radio",
                  disabled: _vm.baseDisabled,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.form, "virtual", $event)
                  },
                },
              }),
              _c("field-tips", {
                attrs: { tipsText: "用于测试或展示的项目不计入统计数据" },
              }),
            ],
            1
          ),
          false
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否考核",
                    rules: [
                      {
                        required: true,
                        message: "请选择是否考核",
                        trigger: "change",
                      },
                    ],
                    prop: "needKpi",
                  },
                },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.needKpiOps,
                      width: _vm.width,
                      disabled: _vm.baseDisabled,
                    },
                    model: {
                      value: _vm.form.needKpi,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "needKpi", $$v)
                      },
                      expression: "form.needKpi",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "cut-line-wrapper" }),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目电话",
                rules: [
                  {
                    required: true,
                    message: "请输入项目电话",
                    trigger: "blur",
                  },
                  { validator: _vm.validatePhone, trigger: "blur" },
                ],
                prop: "communityPhone",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  maxlength: 20,
                  placeholder: "请输入项目电话",
                  width: _vm.lwidth,
                },
                model: {
                  value: _vm.form.communityPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityPhone", $$v)
                  },
                  expression: "form.communityPhone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "项目建筑面积" } },
            [
              _c("el-input", {
                staticClass: "areaInput",
                style: { width: _vm.width + "px" },
                attrs: {
                  type: "text",
                  maxlength: 20,
                  placeholder: "请输入项目建筑面积",
                },
                on: { input: _vm.handleInput },
                model: {
                  value: _vm.form.communityArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityArea", $$v)
                  },
                  expression: "form.communityArea",
                },
              }),
              _vm._v(" m"),
              _c("sup", [_vm._v("2")]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "posStyle",
              attrs: {
                label: "项目经纬度",
                rules: [{ required: true, validator: _vm.itude }],
                prop: "longitude",
              },
            },
            [
              _c("v-input", {
                attrs: { placeholder: "经度", width: _vm.width },
                model: {
                  value: _vm.form.longitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "longitude", $$v)
                  },
                  expression: "form.longitude",
                },
              }),
              _c("v-input", {
                attrs: { placeholder: "纬度", width: _vm.width },
                model: {
                  value: _vm.form.latitude,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "latitude", $$v)
                  },
                  expression: "form.latitude",
                },
              }),
              _c("v-button", {
                attrs: { text: "地图标注" },
                on: { click: _vm.getMap },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目海拔",
                rules: [{ required: true, message: "请输入项目海拔" }],
              },
            },
            [
              _c("v-input-number", {
                attrs: {
                  placeholder: "请输入海拔",
                  max: 99999,
                  width: _vm.width,
                },
                model: {
                  value: _vm.form.elevation,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "elevation", $$v)
                  },
                  expression: "form.elevation",
                },
              }),
              _vm._v(" m\n    "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目区划",
                rules: [
                  {
                    required: true,
                    validator: _vm.validateAreaCode,
                    trigger: "change",
                  },
                ],
                prop: "shequCode",
              },
            },
            [
              _c("area-select2", {
                attrs: {
                  value: _vm.areaValue,
                  placeholder: "按区域选择",
                  width: 500,
                },
                on: {
                  "update:value": function ($event) {
                    _vm.areaValue = $event
                  },
                  change: _vm.areaChange,
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目地址",
                rules: [
                  {
                    required: true,
                    message: "请输入项目地址",
                    trigger: "blur",
                  },
                ],
                prop: "communityAddress",
              },
            },
            [
              _c("v-input", {
                attrs: { placeholder: "请输入项目地址", width: 500 },
                model: {
                  value: _vm.form.communityAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityAddress", $$v)
                  },
                  expression: "form.communityAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "项目邮编",
                rules: [
                  {
                    required: true,
                    validator: _vm.validateCode,
                    trigger: "blur",
                  },
                ],
                prop: "communityCode",
              },
            },
            [
              _c("v-input", {
                attrs: {
                  maxlength: 6,
                  placeholder: "请输入项目邮编",
                  width: _vm.lwidth,
                },
                model: {
                  value: _vm.form.communityCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communityCode", $$v)
                  },
                  expression: "form.communityCode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-map", {
        attrs: {
          selectedLng: Number(_vm.form.longitude),
          selectedLat: Number(_vm.form.latitude),
          showMapDialog: _vm.showMapDialog,
        },
        on: {
          "update:showMapDialog": function ($event) {
            _vm.showMapDialog = $event
          },
          "update:show-map-dialog": function ($event) {
            _vm.showMapDialog = $event
          },
          setLngAndLat: _vm.setLngAndLat,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }